<template>
  <header :class="{ 'is-fixed': isFixed }" v-if="!maintenance">
    <fixed-header v-model:fixed="isFixed">
      <div class="fixed-header" :class="{ 'is-fixed': isFixed }">
        <app-skip-links></app-skip-links>
        <div class="container">
          <div class="content">
            <div class="logo" :class="{ 'logo--20thanniversary': is20thAnniversary }">
              <router-link :to="logoUrl()">
                <img v-if="!highContrast && greyLogo" alt="Culture.pl" src="/static/img/logo-grey.svg" width="194" height="36"/>
                <img v-if="!highContrast && !greyLogo" alt="Culture.pl" src="/static/img/logo.svg" width="194" height="36"/>
                <img v-if="highContrast" alt="Culture.pl" src="/static/img/logo-hc.svg" width="194" height="36"/>
              </router-link>
            </div>
            <component :is="'HeaderText'"
                       v-if="headerText()"
                       :html="headerText()"></component>
            <app-lang-switch-to-frontpage></app-lang-switch-to-frontpage>
            <app-search-switch></app-search-switch>
            <app-contrast-switch></app-contrast-switch>
            <app-nav-switch></app-nav-switch>
            <app-navigation></app-navigation>
          </div>
          <div class="edit-link" v-if="getEditLink()" v-html="getEditLink()"></div>
          <div class="status" :class="getStatus()" v-if="getStatus()" v-html="'<span>' + getStatusText() + '</span>'"></div>
        </div>
        <div class="search-form-container" v-if="searchBar">
          <div class="container">
            <div class="content">
              <component :is="'SearchBar'"></component>
            </div>
          </div>
        </div>
      </div>
    </fixed-header>
  </header>
</template>

<script>
import LangSwitchToFrontpage from '@/components/header/LangSwitchToFrontpage.vue'
import SearchSwitch from '@/components/header/SearchSwitch.vue'
import SearchBar from '@/components/header/SearchBar.vue'
import SkipLinks from '@/components/header/SkipLinks.vue'
import ContrastSwitch from '@/components/header/ContrastSwitch.vue'
import Navigation from '@/components/header/Navigation.vue'
import NavSwitch from '@/components/header/NavSwitch.vue'
import HeaderText from '@/components/header/HeaderText.vue'
import FixedHeader from '@/components/common/vueFixedHeader'
import { highContrast } from '@/components/mixins/highContrastMixin.js'
import { searchBar } from '@/components/mixins/searchBarMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, highContrast, searchBar],
  data () {
    return {
      isFixed: false,
      is20thAnniversary: false
    }
  },
  methods: {
    getEditLink () {
      return this.$store.getters.getEditLink
    },
    getStatus () {
      return this.$store.getters.getNodeStatus
    },
    getStatusText () {
      const status = this.$store.getters.getNodeStatus

      if (status) {
        return this.$t('status.' + status)
      } else {
        return ''
      }
    },
    logoUrl () {
      if (this.$i18n.locale()) {
        return '/' + this.$i18n.locale()
      } else {
        return '/en'
      }
    },
    headerText () {
      if (this.$store.getters.getHeaderText) {
        return this.$store.getters.getHeaderText
      } else {
        return ''
      }
    }
  },
  computed: {
    greyLogo: function () {
      if (this.$store.getters.getLogoAlternativeState) {
        return this.$store.getters.getLogoAlternativeState
      } else {
        return false
      }
    },
    currentLanguage: function () {
      return this.$i18n.locale()
    },
    maintenance: function () {
      return this.$store.getters.getMaintenanceState
    }
  },
  watch: {
    isFixed: function (val) {
      // ToDo: v3 fix? Current implementation should be changed before update vue-fixed-header to latest version
      if (val) {
        document.body.classList.add('header-is-fixed')
      } else {
        document.body.classList.remove('header-is-fixed')
      }
    }
  },
  components: {
    FixedHeader,
    appLangSwitchToFrontpage: LangSwitchToFrontpage,
    appSearchSwitch: SearchSwitch,
    appContrastSwitch: ContrastSwitch,
    appSkipLinks: SkipLinks,
    appNavigation: Navigation,
    appNavSwitch: NavSwitch,
    HeaderText,
    SearchBar
  }
}
</script>
