export const menu = {
  props: {
    menuName: {
      type: String,
      default: ''
    },
    endpointUrl: {
      type: String,
      default: '/api/routing/menu?menu='
    },
    language: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      localeWatcher: () => {},
      fetchRetryCount: 0,
      fetchTimeout: {},
      retried: true
    }
  },
  mounted () {
    if (!this.$store.getMenuFooter) {
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.menu = {
        main: this.$store.getters.getMenuMain,
        footer: this.$store.getters.getMenuFooter,
        subMenu: this.$store.getters.getMenuSub
      }
    },
    externalIsInternal (url) {
      if (url.includes('culture.pl')) {
        return true
      }
      return false
    }
  },
  computed: {
    currentLanguage: function () {
      return this.$i18n.locale()
    }
  },

  created: function () {
    this.localeWatcher = this.$store.watch((state) => state.menu.data, () => {
      this.fetchData()
    })
  },
  beforeUnmount () {
    this.localeWatcher()
  }
}
