import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'
import { GTMHelper } from '@/helpers/gtm.js'
import { logger } from '@/helpers/logger.js'

const vuexCookie = new VuexPersistence({
  restoreState: (key) => {
    const cookie = Cookies.get(key)

    if (cookie) {
      return JSON.parse(cookie)
    }
  },
  saveState: (key, state) => {
    return Cookies.set(key, JSON.stringify(state), { expires: 3, secure: location.protocol === 'https:' })
  },
  reducer: (state) => ({
    user: state.user,
    highContrast: state.highContrast,
    language: state.language
  })
})

export default function (axios) {
  return createStore({
    state: {
      translations: {},
      user: {
        frontpage: '',
        cookiesButtonClicked: false
      },
      preloader: true,
      maintenance: false,
      highContrast: false,
      logoAlternative: false,
      searchBar: false,
      headerText: '',
      updateTime: '',
      windowWidth: 0,
      windowHeight: 0,
      language: false,
      editLink: '',
      nodeStatus: '',
      footer: true,
      GTMHelper: null,
      targetRouteName: null,
      menu: {}
    },
    getters: {
      getTranslations: state => {
        return state.translations
      },
      getPreloaderState: state => {
        return state.preloader
      },
      getMaintenanceState: state => {
        return state.maintenance
      },
      getHighContrastState: state => {
        if (state.highContrast !== true && state.highContrast !== false) {
          state.highContrast = false
          return false
        } else {
          return state.highContrast
        }
      },
      getLogoAlternativeState: state => {
        if (state.logoAlternative !== true && state.logoAlternative !== false) {
          state.logoAlternative = false
          return false
        } else {
          return state.logoAlternative
        }
      },
      getSearchBarState: state => {
        if (state.searchBar !== true && state.searchBar !== false) {
          state.searchBar = false
          return false
        } else {
          return state.searchBar
        }
      },
      getHeaderText: state => {
        return state.headerText
      },
      getUpdateTime: state => {
        return state.updateTime
      },
      getWindowWidth: state => {
        return state.windowWidth
      },
      getWindowHeight: state => {
        return state.windowHeight
      },
      getEditLink: state => {
        return state.editLink
      },
      getNodeStatus: state => {
        return state.nodeStatus
      },
      getLanguage: state => {
        return state.language
      },
      getFooterState: state => {
        return state.footer
      },
      getTargetRouteName: state => {
        return state.targetRouteName
      },
      getMenuMain: state => {
        if (state.menu.data !== undefined) {
          return state.menu.data.main
        }
      },
      getMenuFooter: state => {
        if (state.menu.data !== undefined) {
          return state.menu.data.footer
        }
      },
      getMenuSub: state => {
        if (state.menu.data !== undefined) {
          return state.menu.data['sub-menu']
        }
      },
      getCookiesButtonClicked: state => {
        return state.user.cookiesButtonClicked
      }
    },
    mutations: {
      SET_FRONTPAGE (state) {
        state.user.frontpage = "'/'"
      },
      SET_COOKIES_BUTTON_CLICKED (state, clicked) {
        state.user.cookiesButtonClicked = clicked
      },
      SET_TRANSLATIONS (state, translations) {
        Object.assign(state, translations)
      },
      SET_PRELOADER (state, loading) {
        state.preloader = loading
      },
      SET_MAINTENANCE (state, maintenance) {
        state.maintenance = maintenance
      },
      SET_HIGH_CONTRAST (state, highContrast) {
        state.highContrast = highContrast
      },
      SET_LOGO_ALTERNATIVE (state, logoAlternative) {
        state.logoAlternative = logoAlternative
      },
      SET_SEARCH_BAR (state, searchBar) {
        state.searchBar = searchBar
      },
      SET_HEADER_TEXT (state, html) {
        state.headerText = html
      },
      SET_UPDATE_TIME (state, time) {
        state.updateTime = time
      },
      SET_WINDOW_WIDTH (state, width) {
        state.windowWidth = width
      },
      SET_WINDOW_HEIGHT (state, height) {
        state.windowHeight = height
      },
      SET_EDIT_LINK (state, link) {
        state.editLink = link
      },
      SET_NODE_STATUS (state, status) {
        state.nodeStatus = status
      },
      SET_LANGUAGE (state, language) {
        state.language = language
      },
      SET_FOOTER (state, showFooter) {
        state.footer = showFooter
      },
      SET_GTMHELPER (state) {
        state.gtmHeleper = new GTMHelper()
      },
      GTMHELPER_PUSH_PAGE_LOAD_EVENT (state, url) {
        if (state.gtmHeleper) {
          state.gtmHeleper.pushPageLoadEvent(url)
        }
      },
      GTMHELPER_PUSH_PAGE_LOADED_EVENT (state, entity) {
        state.gtmHeleper.pushPageLoadedEvent(entity)
      },
      GTMHELPER_PUSH_GAEVENT (state, eventData) {
        state.gtmHeleper.pushGAEvent(eventData)
      },
      SET_TARGET_ROUTE_NAME (state, name) {
        state.targetRouteName = name
      },
      SET_MENU (state, menu) {
        state.menu = menu
      }
    },
    actions: {
      negotiateLanguage (context, overrideLanguage) {
        const supportedLanguages = ['pl', 'en', 'ua', 'ru', 'jp', 'kr', 'zhs', 'zht']
        let language = false

        // Language provided by environment.
        if (overrideLanguage !== false) {
          language = supportedLanguages.filter(
            el => el === overrideLanguage
          ).shift()
        } else if (!context.getters.getLanguage) {
          // language provided by browser
          language = supportedLanguages.filter(
            el => el === navigator.language
          ).shift()
        }

        // If we still don't have language, use last saved version
        if (!language && context.getters.getLanguage) {
          language = context.getters.getLanguage
        }
        if (!language && document.globalPreferredLanguage) {
          language = supportedLanguages.filter(
            el => el === document.globalPreferredLanguage
          ).shift()
        }

        // If we have finally negotiated language - use it.
        if (language) {
          context.commit('SET_LANGUAGE', language)
        } else {
          context.commit('SET_LANGUAGE', 'en')
        }
      },
      setFrontpage (context) {
        context.commit('SET_FRONTPAGE')
      },
      setCookiesButtonClicked (context, clicked) {
        context.commit('SET_COOKIES_BUTTON_CLICKED', clicked)
      },
      updateTranslations (context, translations) {
        context.commit('SET_TRANSLATIONS', translations)
      },
      turnOnPreloader (context) {
        context.commit('SET_PRELOADER', true)
      },
      turnOffPreloader (context) {
        const event = new Event('preloaderOff')
        document.dispatchEvent(event)
        context.commit('SET_PRELOADER', false)
      },
      turnOnMaintenance (context) {
        const event = new Event('maintenanceOn')

        document.dispatchEvent(event)
        context.commit('SET_MAINTENANCE', true)
      },
      turnOffMaintenance (context) {
        const event = new Event('maintenanceOff')

        document.dispatchEvent(event)
        context.commit('SET_MAINTENANCE', false)
      },
      turnOnHighContrast (context) {
        context.commit('SET_HIGH_CONTRAST', true)
      },
      turnOffHighContrast (context) {
        context.commit('SET_HIGH_CONTRAST', false)
      },
      turnOnAlternativeLogo (context) {
        context.commit('SET_LOGO_ALTERNATIVE', true)
      },
      turnOffAlternativeLogo (context) {
        context.commit('SET_LOGO_ALTERNATIVE', false)
      },
      turnOnSearchBar (context) {
        context.commit('SET_SEARCH_BAR', true)
      },
      turnOffSearchBar (context) {
        context.commit('SET_SEARCH_BAR', false)
      },
      setHeaderText (context, html) {
        context.commit('SET_HEADER_TEXT', html)
      },
      setUpdateTime (context, time) {
        context.commit('SET_UPDATE_TIME', time)
      },
      setWindowWidth (context, width) {
        context.commit('SET_WINDOW_WIDTH', width)
      },
      setWindowHeight (context, height) {
        context.commit('SET_WINDOW_HEIGHT', height)
      },
      setEditLink (context, link) {
        context.commit('SET_EDIT_LINK', link)
      },
      setNodeStatus (context, status) {
        context.commit('SET_NODE_STATUS', status)
      },
      turnOnFooter (context) {
        context.commit('SET_FOOTER', true)
      },
      turnOffFooter (context) {
        context.commit('SET_FOOTER', false)
      },
      setGTMHelper (context) {
        context.commit('SET_GTMHELPER')
      },
      GTMHelperPushPageLoadEvent (context, url) {
        context.commit('GTMHELPER_PUSH_PAGE_LOAD_EVENT', url)
      },
      GTMHelperPushPageLoadedEvent (context, entity) {
        context.commit('GTMHELPER_PUSH_PAGE_LOADED_EVENT', entity)
      },
      GTMHelperPushGAEvent (context, eventData) {
        context.commit('GTMHELPER_PUSH_GAEVENT', eventData)
      },
      setTargetRouteName (context, name) {
        context.commit('SET_TARGET_ROUTE_NAME', name)
      },
      async SetMenu (context, language) {
        let menu
        const url = process.env.VUE_APP_ENDPOINT_URL + language + '/api/routing/menu?menu=all'
        let options = {}
        if (process.env.VUE_APP_ENDPOINT_AUTHORIZATION_HEADER !== '') {
          const authHeader = 'Basic ' + btoa(process.env.VUE_APP_ENDPOINT_AUTHORIZATION_HEADER)
          options = {
            url,
            method: 'GET',
            headers: {
              Authorization: authHeader
            }
          }
        } else {
          options = {
            url,
            method: 'GET'
          }
        }
        logger('Menu endpoint:', options)
        await axios.request(options).then(response => {
          if (response.data.meta && response.data.meta.errors) {
            for (let index = 0; index < response.data.meta.errors.length; index++) {
              logger(response.data.meta.errors[index].detail + ' - ' + response.data.meta.errors[index].id)
            }
          }
          menu = response.data
          context.dispatch('setUpdateTime', Date.now())
          logger('Menu response:', response.data)
          context.commit('SET_MENU', menu)
        }, response => {
          this.fetchRetryCount++
          if (this.fetchRetryCount <= process.env.VUE_APP_MAX_FETCH_RETRY_COUNT) {
            if (this.retried) {
              // if timeout was set already
              // clear it, before set new one
              clearTimeout(this.fetchTimeout)
            }
            this.retried = true
            logger('Menu communication error. Retry count: ' + this.fetchRetryCount)
            const vm = this
            this.fetchTimeout = setTimeout(function () {
              vm.fetchData()
            }, process.env.VUE_APP_FETCH_TIMEOUT)
          } else {
            if (this.retried) {
              clearTimeout(this.fetchTimeout)
            }
          }
          logger('Menu communication error', response)
        })
      }
    },
    modules: {},
    plugins: [vuexCookie.plugin]
  })
}
